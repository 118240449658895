import axios from "axios"
import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import plus from "../images/gasspent2.png"
import star from "../images/profits.png"
import minus from "../images/totalspent.png"
import equal from "../images/portfoliotfinal.png"

const StyledStats = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* margin-left: -1rem; */
  margin-top: -1rem;
  /* margin-left: 15px;
  margin-right: 15px; */
  justify-content: space-evenly;

  .nftAllocation {
    will-change: transform;
    backface-visibility: hidden;
    background: rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(10px);
    box-shadow: rgb(0 0 0 / 1%) 16px 1px 14px;
    border-radius: 16px;
    padding: 16px 24px 13px;
    margin-bottom: 50px;
    /* min-width: 240px;
    max-width: 500px; */
    flex: 0 1 40%;

    @media only screen and (max-width: 606px) {
      flex: 1 0 40%;
    }
  }

  .allocationTitle {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;

    svg {
      display: inline-block;
      font-size: inherit;
      height: 1em;
      overflow: visible;
      vertical-align: -0.125em;
      overflow: visible;
      width: 1em;
    }

    .allocationName {
      vertical-align: top;
      display: inline-grid;
      gap: 8px;
      font-size: 20px;
      align-items: center;
      grid-auto-flow: column;
      justify-content: flex-start;

      h3 {
        font-size: 0.9rem;
        font-family: "Metropolis", sans-serif;
        font-weight: bold;
        line-height: 1.167;
        margin-top: 12px;
        margin-bottom: 10px;
      }
    }
  }

  hr {
    margin: 1rem 0px;
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
    background-color: rgba(255, 255, 255, 0.12);
  }

  .root-right {
    margin-left: 75px !important;
  }

  .bottomSectionAllocation {
    display: grid;

    .project {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      padding: 0.4rem 0px;
      margin-bottom: 5px;
      justify-content: space-between;

      .projectName {
        vertical-align: center;
        display: inline-grid;
        gap: 8px;
        align-items: center;
        max-width: 45%;
        grid-auto-flow: column;
        margin-right: 4px;
        justify-content: flex-start;
        max-width: 60%;

        p {
          font-size: 11px;
          font-family: "Roboto", sans-serif;
          font-weight: normal;
          line-height: 1.5;
          color: #ffffff;

          margin: 0;
        }
      }

      .bar-percentage {
        flex: 1 1 0%;
        height: 8px;
        background-color: rgba(255, 255, 255, 0.5);
        margin: 0px 0.5rem;
        height: 4px;
        overflow: hidden;
        position: relative;
        border-radius: 8px;
        min-width: 40%;
        max-width: 40%;
        /* min-width: 80px; */

        .completed {
          border-radius: 5px;
          transition: transform 0.4s linear;
          top: 0;
          left: 0;
          width: 100%;
          bottom: 0;
          position: absolute;
          transition: transform 0.2s linear;
          transform-origin: left;
        }
      }

      .percentage {
        text-align: right;

        p {
          line-height: 1;
          display: block;
          color: #ffffff;
          font-size: 14px;
          font-family: "Roboto", sans-serif;
          font-weight: normal;
        }
      }
    }
  }

  .dataContainer {
    flex: 0 0 45%;
    margin-bottom: 50px;
    @media only screen and (max-width: 606px) {
      flex: 1 0 40%;
    }
  }

  .first-section,
  .second-section {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .first-section {
    margin-bottom: 20px;
  }

  .image-second {
    /* margin-left: -20px;margin-right: 20px; */
    width: 65px;
    margin-bottom: 20px;
    margin-right: 25px;
    margin-left: 10px;
  }

  .statistics {
    will-change: transform;
    backface-visibility: hidden;
    backdrop-filter: blur(10px);
    position: relative;
    border-radius: 16px;
    background: linear-gradient(146.06deg, #007acc, #a2e5b5);
    padding: 2rem 1.5rem 0rem;
    box-shadow: rgb(0 0 0 / 26%) 0px 6px 15px 1px;
    z-index: 1;
    color: white;
  }
  .root {
    margin-left: 0px;
  }

  .title {
    margin-bottom: 0.35em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    line-height: 1.43;
    font-weight: 700;
  }

  .number {
    font-size: 34px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: "Metropolis", sans-serif;
    font-weight: bold;
    line-height: 1.167;
    margin: 0;
  }

  .difference {
    vertical-align: top;
    display: inline-grid;
    gap: 4px;
    margin-top: 8px;
    align-items: center;
    grid-auto-flow: column;
    justify-content: flex-start;
  }

  .tag {
    color: white;
    font-weight: bold;
    background-color: rgb(158, 209, 22);
    height: 24px;
    color: #fff;
    border: none;
    cursor: default;
    height: 32px;
    display: inline-flex;
    outline: 0;
    padding: 0;
    font-size: 0.8125rem;
    box-sizing: border-box;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    font-family: "Roboto", sans-serif;
    white-space: nowrap;
    border-radius: 16px;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: #616161;

    .info {
      padding-left: 8px;
      padding-right: 8px;
      overflow: hidden;
      white-space: nowrap;
      padding-left: 12px;
      padding-right: 12px;
      text-overflow: ellipsis;
    }
  }

  /* svg {
    color: white;
    font-size: 18px;
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
  } */

  img {
    width: 70px;
    margin-right: 20px;
    margin-bottom: 0;
    margin-left: 10px;
  }

  .bottom-data {
    will-change: transform;
    backface-visibility: hidden;
    background: rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(10px);
    box-shadow: 0px 22px 20px rgb(0 0 0 / 24%);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding: 4.5rem 1.5rem 0rem;
    border-radius: 0px 0px 30px 30px;
    margin-top: -3rem;
  }
  .bottom-section {
    img {
      position: absolute;
      width: 36px;
      user-select: none;
      margin-left: 15px;
      margin-top: 10px;
    }

    .negative {
      margin-left: 25px;
    }

    .bottom-root {
      margin-left: 60px;
      .bottom-title {
        display: block;
        color: #adadad;
        margin-bottom: 0.35em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 0.75rem;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        line-height: 1.66;
      }

      h3 {
        color: #ffffff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 18px;
        font-family: "Metropolis", sans-serif;
        font-weight: bold;
        line-height: 1.167;
        margin-top: 0;
      }
    }
  }
`

export default class NftStats extends Component {
  constructor(props) {
    super(props)
    this.renderAllocationsPercentages = this.renderAllocationsPercentages.bind(
      this
    )
    this.getJerryApi = this.getJerryApi.bind(this)
    this.state = {
      accountInfo: [],
    }
  }

  async componentDidMount() {
    await this.getJerryApi()
  }

  async getJerryApi(
    address = this.props.address || "0x7777C237d6d472656c1A5859F61b9F6d2C1a827B"
  ) {
    try {
      const api = `https://jerry543.com:8000/account_details/${address}/`
      const data = await axios.get(api)
      const ethReceived = data?.data?.eth_received //eth received from sales
      const ethSpent = data?.data?.eth_spent
      const gasSpent = data?.data?.gas_spent
      const accountInfo = {
        ethReceived,
        ethSpent,
        gasSpent,
      }
      console.log("accountInfo ", accountInfo)
      this.setState({ accountInfo })
    } catch (err) {
      console.log("err ", err)
    }
  }

  renderAllocationsPercentages() {
    const shownCollections = this.props.nftCardsData?.collectionPercentage.slice(
      0,
      5
    )
    let htmlTags = ""
    if (shownCollections) {
      for (let j in shownCollections) {
        htmlTags += `<div class="project">
      <div class="projectName">
        <div class="projectTypo">
          <p>${shownCollections[j].actualName}</p>
        </div>
      </div>
      <div
        class="bar-percentage"
        barcolor="linear-gradient(270deg, #4301A1 1.33%, #6601F7 ${shownCollections[
          j
        ].percentage.toFixed(0)}%)"
        aria-valuenow=${shownCollections[j].percentage.toFixed(0)}
        aria-valuemin="0"
        aria-valuemax="100"
        role="progressbar"
      >
        <div
          class="completed"
          style="transform: translateX(-${
            100 - shownCollections[j].percentage
          }%); background: linear-gradient(270deg, #007acc 0.33%, #a2e5b5 71%);"
        ></div>
      </div>
    </div>`
      }
    }
    return htmlTags
  }

  render() {
    console.log(
      "nftCardsData ",
      this.props.nftCardsData?.collectionPercentage.slice(0, 5)
    )
    const shownCollections = this.props.nftCardsData?.collectionPercentage.slice(
      0,
      5
    )
    return (
      <StyledStats>
        <div className="dataContainer">
          <div className="statistics">
            <div className="first-section">
              <img
                draggable="false"
                src={equal}
                className="image-first"
                style={{ transform: "translate3d(-9.755px, -0.89px, 0px)" }}
              ></img>
              <div className="root">
                <p className="title">
                  <b>PORTFOLIO VALUE</b>
                </p>
                <h1 className="number">
                  {this.props.nftCardsData?.floorValue
                    ? `Ξ ${this.props.nftCardsData?.floorValue.toFixed(3)}`
                    : `Ξ Loading`}
                </h1>
                {/* <div className="difference"> */}
                {/* <div className="tag">
                    <span className="info">+ $4.374</span>
                  </div> */}
                {/* <svg
                    className="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style={{ color: "white; font-size: 18px" }}
                    title="Daily Estimated Return (BSC only)"
                  > */}
                {/* <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path> */}
                {/* </svg> */}
                {/* </div> */}
              </div>
            </div>
            <div className="second-section">
              <img
                draggable="false"
                src={star}
                className="image-second"
                style={{ transform: "translate3d(-9.765px, -2.165px, 0px)" }}
              />
              <div className="root">
                <p className="title">
                  <b>REALIZED PROFITS</b>
                </p>
                <h1 className="number">
                  {!isNaN(this.state.accountInfo?.ethReceived) &&
                  this.props.nftCardsData?.floorValue
                    ? `Ξ ${(
                        this.state.accountInfo.ethReceived -
                        this.state.accountInfo.ethSpent -
                        this.state.accountInfo.gasSpent
                      ).toFixed(3)}`
                    : "Loading"}
                </h1>
              </div>
            </div>
          </div>
          <div className="bottom-data">
            <div className="bottom-section">
              <img
                draggable="false"
                src={plus}
                className="positive"
                style={{ transform: "translate3d(-9.755px, -5.61px, 0px)" }}
              />
              <div className="bottom-root">
                <span className="bottom-title">
                  <b>GAS SPENT</b>
                </span>
                <h3 className="bottom-number">
                  {this.state.accountInfo?.gasSpent
                    ? `Ξ ${this.state.accountInfo?.gasSpent.toFixed(3)}`
                    : "Loading"}
                </h3>
              </div>
            </div>
            <div className="bottom-section">
              <img
                draggable="false"
                src={minus}
                className="negative"
                style={{ transform: "translate3d(-9.755px, -5.61px, 0px)" }}
              />
              <div className="bottom-root root-right">
                <span className="bottom-title">
                  <b>TOTAL SPENT</b>
                </span>
                <h3 className="bottom-number">
                  {" "}
                  {this.state.accountInfo?.ethSpent
                    ? `Ξ ${this.state.accountInfo?.ethSpent.toFixed(3)}`
                    : "Loading"}
                </h3>
              </div>
            </div>
          </div>
        </div>
        {/* new section starting */}
        <div className="nftAllocation">
          <div className="allocationTitle">
            <div className="allocationName">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="cubes"
                className="svg-inline--fa fa-cubes fa-w-16 "
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                // style={{ fontSize: "17px" }}
              >
                <path
                  fill="currentColor"
                  d="M488.6 250.2L392 214V105.5c0-15-9.3-28.4-23.4-33.7l-100-37.5c-8.1-3.1-17.1-3.1-25.3 0l-100 37.5c-14.1 5.3-23.4 18.7-23.4 33.7V214l-96.6 36.2C9.3 255.5 0 268.9 0 283.9V394c0 13.6 7.7 26.1 19.9 32.2l100 50c10.1 5.1 22.1 5.1 32.2 0l103.9-52 103.9 52c10.1 5.1 22.1 5.1 32.2 0l100-50c12.2-6.1 19.9-18.6 19.9-32.2V283.9c0-15-9.3-28.4-23.4-33.7zM358 214.8l-85 31.9v-68.2l85-37v73.3zM154 104.1l102-38.2 102 38.2v.6l-102 41.4-102-41.4v-.6zm84 291.1l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6zm240 112l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6z"
                ></path>
              </svg>
              <h3 className="type">NFT Allocation</h3>
            </div>
          </div>
          <hr className="divider" style={{ margin: "1rem 0px" }} />

          <div className="bottomSectionAllocation">
            <div
              dangerouslySetInnerHTML={{
                __html: this.renderAllocationsPercentages(),
              }}
            />
          </div>
        </div>
      </StyledStats>
    )
  }
}
