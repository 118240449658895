import axios from "axios"
import React, { Component, useState } from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import _ from "lodash"
// import FindPaidForMint from "./moralis"
import { Moralis } from "moralis"

const NftLayout = styled.div`
  /* width: 52rem; */
  .wrap-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: row;
  }
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    /* margin-right: 15px;
    margin-left: 15px; */
  }
  .card-item {
    flex-wrap: nowrap;
    user-select: none;
    backface-visibility: hidden;
  }
  .card-root {
    display: flex;
    flex-direction: row;
    color: #192c30;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #22212c;
    box-shadow: 0px 22px 20px rgb(0 0 0 / 4%);
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    overflow: hidden;
    position: relative;
    max-width: 22rem;
    /* height: 131px; */
    /* transform: translateZ(0); */
    border-radius: 16px;
    min-width: 19rem;
    flex: 1 1 auto;
    height: 135px;
  }
  .bgimage-container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: linear-gradient(107.97deg, #586a6d 0%, #22212c 100%);
  }
  video {
    position: absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;
    min-width: 100%;
  }
  .bgimage {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }
  .image-container {
    width: 36%;
    /* height: 0; */
    position: relative;
    flex-shrink: 0;
    padding-bottom: 36%;
  }
  .image {
    opacity: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }
  .right-side {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: white;
    z-index: 1;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0 16px 16px 0;
  }
  .type {
    font-size: 0.717rem;
    margin-top: 15px;
    margin-left: 10px;
    align-self: flex-start;
    margin-bottom: 0;
  }
  h5 {
    color: rgba(255, 255, 255, 0.4);
    margin-top: 5px;
    margin-left: 10px;
    align-self: flex-start;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 2px;
  }
  .boughtFor,
  .estimate,
  .floor {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    .titlebought,
    .floorprice {
      color: rgba(255, 255, 255, 0.75);
      font-size: 0.617rem;
    }
    .estimatedprice {
      color: rgba(255, 255, 255, 0.75);
      font-size: 0.617rem;
    }
    .flex {
      justify-content: space-between;
    }
    .price {
      font-size: 0.617rem;
      font-weight: 600;
    }
  }

  .flex {
    display: flex;
  }
`

function amount(item) {
  return item.floor
}

function sum(prev, next) {
  return prev + next
}

function timer(ms) {
  return new Promise(res => setTimeout(res, ms))
}

async function task(i) {
  // 0.5 seconds each
  await timer(500)
  console.log(`Task ${i} done!`)
}

export default class SearchAccountNft extends Component {
  constructor(props) {
    super(props)
    this.getAssets = this.getAssets.bind(this)
    this.renderCard = this.renderCard.bind(this)
    this.getFloor = this.getFloor.bind(this)
    this.callOpenSea = this.callOpenSea.bind(this)
    this.findPaidForMint = this.findPaidForMint.bind(this)
    this.calculateStats = this.calculateStats.bind(this)
    this.getExpectedValue = this.getExpectedValue.bind(this)
    this.state = {
      assets: [],
    }
  }

  async componentDidMount() {
    await this.getAssets()
    await this.getFloor(this.state.assets)
    await this.findPaidForMint(this.state.assets)
    this.calculateStats()
  }

  calculateStats() {
    const data = this.state.assets
    const floorValue = data.map(amount).reduce(sum)
    console.log("value based on floor", floorValue)
    const objectPercentage = this.state?.collections
      ?.map(collection => {
        const value = collection.floor * collection.amount
        const percentage = (value * 100) / floorValue
        const obj = { ...collection, value, percentage }
        return obj
      })
      .sort((a, b) => parseFloat(b.percentage) - parseFloat(a.percentage))

    const fullData = { floorValue, collectionPercentage: objectPercentage }
    this.props.receiveNftsCardsData(fullData)
  }

  async callOpenSea(
    address = this.props.address || "0x7777C237d6d472656c1A5859F61b9F6d2C1a827B"
  ) {
    let flag = true
    let query_obj = { limit: 50, offset: 0, parallel: 3 }
    const assets = []
    while (flag) {
      const waitAll = _.range(query_obj.parallel).map(idx =>
        axios.get(
          "https://api.opensea.io/api/v1/assets?order_direction=desc&limit=" +
            query_obj.limit +
            "&offset=" +
            (query_obj.offset + idx * query_obj.limit),
          { params: { owner: address } }
        )
      )

      const results = await Promise.all(waitAll)
      results.map(result => assets.push(...result?.data?.assets))
      // assets.push(result?.data?.assets)
      flag = results?.filter(data => data.data.assets.length === 0).length === 0
      query_obj.offset += query_obj.limit * query_obj.parallel
    }
    return assets
  }

  async getAssets(
    address = this.props.address || "0x7777C237d6d472656c1A5859F61b9F6d2C1a827B"
  ) {
    const assets = await this.callOpenSea(address)

    const newAssetsWithoutUnidentified = await assets
      .map(el =>
        !el?.asset_contract?.name
          .toLowerCase()
          .includes("unidentified contract") ||
        !el?.asset_contract?.name.toLowerCase().includes("untitled collection")
          ? el
          : null
      )
      .filter(function (el) {
        return el != null
      })

    console.log("newAssetsWithoutUnidentified ", newAssetsWithoutUnidentified)
    if (newAssetsWithoutUnidentified) {
      this.setState({ assets: newAssetsWithoutUnidentified })
    }
  }

  async getFloor(assets) {
    try {
      //INFO: optimized for single call for collection
      let newAssets = []
      let collections = []

      for (let i in assets) {
        try {
          const collection = {
            name: assets[i].collection.slug,
            actualName: assets[i].collection?.name,
            address: assets[i].asset_contract?.address,
          }
          const id = assets[i].token_id
          if (!collections.find(el => el.name === collection.name)) {
            const amount = 1
            const obj = { ...collection, amount: amount, ids: [id] }
            collections.push(obj)
          } else if (collections.find(el => el.name === collection.name)) {
            const index = collections
              .map(function (e) {
                return e.name
              })
              .indexOf(assets[i].collection.slug)
            const amount = collections[index].amount
            collections[index].ids.push(id) // adding the id to the list
            const obj = { ...collections[index], amount: amount + 1 }
            collections[index] = obj
          }
        } catch (err) {
          console.log("err assets", err)
        }
      }

      async function cachedCollections(collectionName) {
        if (typeof window !== "undefined") {
          const timeNow = new Date().getTime()
          const timeExpiration = timeNow + 1000 * 3600 * 1 // 1 h
          let dataAPIs
          let usedCache = false
          let localData = window.localStorage.getItem(`coll:${collectionName}`)
          if (
            localData === null ||
            window.localStorage.getItem(`timeout:collections`) < timeNow
          ) {
            const floorCall = `https://api.opensea.io/api/v1/collection/${collectionName}`
            dataAPIs = await axios.get(floorCall)
            window.localStorage.setItem(`timeout:collections`, timeExpiration)
            window.localStorage.setItem(
              `coll:${collectionName}`,
              JSON.stringify(dataAPIs)
            )
          } else {
            dataAPIs = JSON.parse(
              window.localStorage.getItem(`coll:${collectionName}`)
            )
            usedCache = true
          }

          const floor = dataAPIs?.data.collection.stats.floor_price
          return {
            floor,
            usedCache,
          }
        }
      }

      for (let i in collections) {
        try {
          const floorData = await cachedCollections(collections[i].name)
          const { floor, usedCache } = floorData

          const getExpectedValues = await this.getExpectedValue(collections[i])
          collections[i] = {
            ...collections[i],
            floor,
            expectedValues: getExpectedValues,
          }
          if (usedCache === false) {
            await task(1)
          }
        } catch (err) {
          console.log("err collections", err)
        }
      }

      console.log("floor collection ", collections)

      if (collections) {
        this.setState({ collections })
        console.log("collections ", collections)
      }

      for (let i in assets) {
        const collection = assets[i].collection.slug
        const id = assets[i].token_id
        const correctFloor = collections.filter(function (el) {
          return el.name === collection
        })[0]
        console.log("correctFloor ", correctFloor)
        let expectedValue
        try {
          if (correctFloor.expectedValues !== undefined) {
            for (let i in correctFloor.expectedValues) {
              console.log("expectedValue1 ", i)
              if (i === id) {
                expectedValue = correctFloor.expectedValues[i]
              }
            }
          }
        } catch (err) {
          console.error(err)
        }
        const newAsset = {
          ...assets[i],
          floor: correctFloor.floor,
          estimatedPrice: expectedValue?.estimated_price,
          rarityRank: expectedValue?.rarity_rank,
        }
        newAssets.push(newAsset)
      }
      this.setState({ assets: newAssets })
    } catch (err) {
      console.log("error s", err)
    }
  }

  async findPaidForMint(
    assets,
    address = this.props.address || "0x7777C237d6d472656c1A5859F61b9F6d2C1a827B"
  ) {
    Moralis.initialize(
      "62soVuZ7qPuBhmrkvkLQasNpa3INOH62WnO6bWJf",
      "",
      "IOBmam0sLaoN6arNGyl2xIfiPU6TxYPHA7XZN4No"
    )
    Moralis.serverURL = "https://60qk6ir9auva.moralishost.com:2053/server"
    let flag = true
    const options = { chain: "eth", address, limit: 500, offset: 0 }
    const allNFTTransactions = []

    while (flag) {
      const enter = await Moralis.Web3API.account.getNFTTransfers(options)
      console.log("enter ", enter?.result.length === 0)
      allNFTTransactions.push(...enter?.result)
      // console.log("enter ", enter)
      // assets.push(result?.data?.assets)
      flag = !(enter?.result.length === 0)
      options.offset += options.limit
    }
    // const allNFTTransactions = await Moralis.Web3API.account.getNFTTransfers(
    //   options
    // )
    console.log("allNFTTransactions ", allNFTTransactions)
    console.log(
      "filter nft ",
      allNFTTransactions.filter(
        nft =>
          nft.token_address === "0x495f947276749ce646f68ac8c248420045cb7b5e"
      )
    )
    const minted = []
    for (let i in assets) {
      if (isNaN(assets[i]?.last_sale?.total_price * Math.pow(10, -18))) {
        minted.push(assets[i])
      }
    }
    for (let i in minted) {
      const id = minted[i].token_id
      console.log("id ", id)
      const collectionContract = minted[i].asset_contract.address
      console.log("collectionContract ", collectionContract)
      const filterTransactionsNFT = allNFTTransactions.filter(
        el =>
          el.token_address === collectionContract &&
          el.to_address.toLowerCase() === address.toLowerCase() &&
          el.from_address === "0x0000000000000000000000000000000000000000"
      )
      const filterCreatedNFT = allNFTTransactions.filter(
        el =>
          el.token_address === collectionContract &&
          el.from_address.toLowerCase() === address.toLowerCase()
      )
      const filterTransferredNFT = allNFTTransactions.filter(
        el =>
          el.token_address === collectionContract &&
          el.from_address.toLowerCase() !== address.toLowerCase() &&
          el.from_address.toLowerCase() !==
            "0x0000000000000000000000000000000000000000" &&
          el.to_address.toLowerCase() === address.toLowerCase()
      )
      if (
        id ===
        "21424250214574860151985295016780488421893444491732158635922004506238565482497"
      ) {
        console.log("filterTransferredNFT ", filterTransferredNFT)
        console.log("filterCreatedNFT ", filterCreatedNFT)
      }
      const filterCreatedNFTSingular = filterCreatedNFT[0]
      const filterTransferredNFTSingular = filterTransferredNFT[0]
      const filterTransactionsNFTSingular = filterTransactionsNFT[0]
      const amountOfCardsMinted = allNFTTransactions.filter(
        el =>
          el?.token_address === collectionContract &&
          el?.transaction_hash ===
            filterTransactionsNFTSingular?.transaction_hash
      ).length
      const mintValue = filterTransactionsNFTSingular?.value * Math.pow(10, -18)
      const creationValue = filterCreatedNFTSingular?.value * Math.pow(10, -18)
      const transferValue =
        filterTransferredNFTSingular?.value * Math.pow(10, -18)
      let mintCost
      if (!isNaN(mintValue)) {
        mintCost = mintValue / amountOfCardsMinted
      } else if (!isNaN(creationValue)) {
        mintCost = 0
      } else if (!isNaN(transferValue)) {
        mintCost = 0
      }
      console.log("mintValue ", mintValue)
      console.log("amountOfCardsMinted ", amountOfCardsMinted)
      minted[i] = {
        ...minted[i],
        mintTotalCost: mintValue,
        mintAmount: amountOfCardsMinted,
        mintCost,
        isMinted: !isNaN(mintValue),
        isCreatedOrTransferred: !isNaN(creationValue) || !isNaN(transferValue),
      }
    }
    let newAssets = assets.map(obj => {
      const mintFind = minted.find(
        mint =>
          mint.token_id === obj.token_id &&
          mint.asset_contract.address === obj.asset_contract.address
      )
      return mintFind || obj
    })
    console.log("newAssets", newAssets)
    this.setState({ assets: newAssets })
  }

  async getExpectedValue(collection) {
    try {
      let idsList = ""
      const nftIDs = collection.ids
      const collectionAddress = collection.address
      for (let i in nftIDs) {
        if (idsList === "") {
          idsList = `?IDs=${nftIDs[i]}`
        } else {
          idsList += `&IDs=${nftIDs[i]}`
        }
      }
      const floorCall = `https://jerry543.com:8000/details/${collectionAddress}/${idsList}&method=avg`
      const dataAPIs = await axios.get(floorCall)
      return dataAPIs?.data
    } catch (err) {
      console.log("error with expected value ", err)
    }
  }

  renderCard() {
    var indents = []
    for (let i in this.state.assets) {
      const { isMinted, isCreatedOrTransferred } = this.state.assets[i]
      const isBought = !isNaN(
        this.state.assets[i]?.last_sale?.total_price * Math.pow(10, -18)
      )
      let displayText = "BOUGHT FOR"
      let displayValue = "Loading"
      let displayAppending = "ETH"
      if (isMinted) {
        displayText = "MINTED FOR"
        displayValue = this.state.assets[i]?.mintCost?.toFixed(4)
      } else if (isCreatedOrTransferred) {
        displayText = "NFT CREATED OR TRANSFERRED"
        displayValue = ""
        displayAppending = ""
      } else if (isBought) {
        displayValue = (
          this.state.assets[i]?.last_sale?.total_price * Math.pow(10, -18)
        ).toFixed(3)
      }
      indents.push(
        <div className="cards-container">
          {/* <FindPaidForMint /> */}
          <div className="card-item">
            <div className="card-root">
              <div className="bgimage-container">
                {/* <img
          alt
          className="bgimage"
          src="https://media.niftygateway.com/image/upload/w_20,e_blur:500/v1632204990/AMatthew/MissAlSimpsonSep22/mas-2-icon_vpr4yn.jpg"
          draggable="false"
        ></img> */}
              </div>
              <div className="image-container">
                {this.state.assets[i]?.image_url.substr(
                  this.state.assets[i]?.image_url.length - 3
                ) === "mp4" && (
                  <video autoplay loop muted playsinline>
                    <source
                      src={this.state.assets[i]?.image_url}
                      type="video/mp4"
                    />
                  </video>
                )}
                {this.state.assets[i]?.image_url.substr(
                  this.state.assets[i]?.image_url.length - 3
                ) !== "mp4" && (
                  <img
                    alt
                    className="image"
                    src={
                      this.state.assets[i]?.image_url ||
                      this.state.assets[i]?.collection?.image_url
                    }
                    draggable="false"
                  ></img>
                )}

                {/* <img
          alt
          src="https://media.niftygateway.com/image/upload/w_20,e_blur:500/v1632204990/AMatthew/MissAlSimpsonSep22/mas-2-icon_vpr4yn.jpg"
          draggable="false"
          style={{
            pointerEvents: "none",
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "129px",
            height: "129px",
            opacity: "0",
            transition: "opacity 1s ease 0s",
            objectFit: "cover",
          }}
        ></img> */}
              </div>
              <div className="right-side">
                <h4 className="type">
                  {this.state.assets[i]?.collection?.name.length > 21
                    ? this.state.assets[i]?.collection?.name.slice(0, 19) +
                      "..."
                    : this.state.assets[i]?.collection?.name || "loading"}
                </h4>
                <h5>
                  #
                  {this.state.assets[i]?.token_id.length > 17
                    ? this.state.assets[i]?.token_id.slice(0, 17) + "..."
                    : this.state.assets[i]?.token_id || "loading"}
                </h5>
                <div className="boughtFor">
                  <p className="titlebought">{displayText}</p>
                  <p className="price">
                    {displayValue} {displayAppending}
                  </p>
                </div>
                <div className="floor">
                  <p className="floorprice">FLOOR PRICE</p>
                  <p className="price">
                    {this.state.assets[i]?.floor?.toFixed(3) || "Loading"} ETH
                  </p>
                </div>
                {this.state.assets[i]?.estimatedPrice && (
                  <div className="estimate">
                    <p className="estimatedprice">ESTIMATE</p>
                    <p className="price">
                      {this.state.assets[i]?.estimatedPrice?.toFixed(3) ||
                        "Loading"}{" "}
                      ETH
                    </p>
                  </div>
                )}
                {!this.state.assets[i]?.estimatedPrice && (
                  <div className="estimate">
                    <p className="estimatedprice">ESTIMATE NOT AVAILABLE</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    }
    return <div className="wrap-content">{indents}</div>
  }

  render() {
    return <NftLayout>{this.renderCard()}</NftLayout>
  }
}
