import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import SearchAccountNft from "../components/search-account-nft"
import NftStats from "../components/nft-stats"
import SearchField from "react-search-field"
import styled from "styled-components"
// import { MoralisProvider } from "react-moralis"

import Bio from "../components/bio"

const StyledSearch = styled.div`
  display: flex;

  .center {
    align-self: center !important;
    width: 51rem;
  }

  .react-search-field {
    width: 100%;
    border: none !important;
  }

  .react-search-field-input {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #22212c;
    color: #59d28f !important;
  }

  input::placeholder {
    color: #59d28f;
  }

  button {
    border-left: none !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #151320 !important;

    svg {
      fill: #59d28f !important;
    }
  }
`

class nftPage extends Component {
  constructor(props) {
    super(props)
    this.onBlur = this.onBlur.bind(this)
    this.receiveNftsCardsData = this.receiveNftsCardsData.bind(this)
    this.state = {
      nftCardsData: null,
      account: "",
    }
  }

  receiveNftsCardsData(childData) {
    this.setState({ nftCardsData: childData })
  }

  onBlur(e) {
    console.log("e ", e)
    this.setState({ account: e })
  }

  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteSocials = this.props.data.site.siteMetadata.social
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        social={siteSocials}
      >
        {/* <MoralisProvider appId="xxxxxxxx" serverUrl="xxxxxxxx"> */}
        <SEO title="Joseph.to - a dev blog" />
        <Bio />
        {this.state.account === "" && (
          <StyledSearch>
            <div className="center">
              <SearchField placeholder="ETH Address..." onBlur={this.onBlur} />
            </div>
          </StyledSearch>
        )}

        {this.state.account !== "" && (
          <div>
            <NftStats
              nftCardsData={this.state.nftCardsData}
              address={this.state.account}
            />
            <SearchAccountNft
              receiveNftsCardsData={this.receiveNftsCardsData}
              address={this.state.account}
            />
          </div>
        )}
        {/* <NftStats nftCardsData={this.state.nftCardsData} />
        <SearchAccountNft receiveNftsCardsData={this.receiveNftsCardsData} /> */}
        <div></div>
        {/* </MoralisProvider> */}
      </Layout>
    )
  }
}

// const nftPage = ({ data, location }) => {
//   const siteTitle = data.site.siteMetadata.title
//   const siteSocials = data.site.siteMetadata.social
//   return (
//     <Layout location={location} title={siteTitle} social={siteSocials}>
//       {/* <MoralisProvider appId="xxxxxxxx" serverUrl="xxxxxxxx"> */}
//       <SEO title="Joseph.to - a dev blog" />
//       <Bio />
//       <NftStats />
//       <SearchAccountNft />
//       <div></div>
//       {/* </MoralisProvider> */}
//     </Layout>
//   )
// }

export default nftPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          twitter
          github
          instagram
        }
      }
    }
  }
`
